<template>
  <v-sheet :height="'40vh'" tile>
    <v-img :key="image" :src="image" max-height="100%"> </v-img>
  </v-sheet>
</template>

<script>
export default {
  data: () => ({
    titles: {
      home: {
        title: 'Välkommen till Majostugan',
        subtitle: 'i Ljusnedal, Funäsfjällen',
      },
      stugan: {
        title: 'Stugan',
        subtitle: 'Om du vill veta mer om sovplatser och våra sällsapsytor',
      },
      trivsel: {
        title: 'Trivsel',
        subtitle: '',
      },
    },
  }),

  computed: {
    namespace() {
      if (!this.$route || !this.$route.name) return '';

      return this.$route.name.toLowerCase();
    },
    image() {
      return `/static/${this.namespace}-hero.jpg`;
    },
    subtitle() {
      return this.titles[this.namespace].subtitle;
    },
    title() {
      return this.titles[this.namespace].title;
    },
  },
};
</script>
